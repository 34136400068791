import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import "../styles/mdTemplate.scss"

export default function Template({ data, pageContext, location }) {
  var { menuData } = pageContext
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout menuData={menuData} location={location}>
      <Seo title={frontmatter.title} />
      <div className="md-container">
        <h1 className="title">{frontmatter.title}</h1>
        <div
          className="md-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
